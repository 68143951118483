.countdown-container {
    /* background: url('./images/img-home.jpg') center center/cover no-repeat; */
    background-color: #252e48;
    color: #fff;
    height: 100vh;
    width: 100%;
   /*( box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
  }

.timer {
    font-size: 7rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.timer-img{
  margin-bottom: 1rem;
}

img {
  position: relative;
  z-index: 0;
}