.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  align-items: center;
  height: 80px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  font-size: 2rem;
}